import { Switch, Route, Link } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonItemGroup,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from "@ionic/react";
import {
  MdHome,
  MdMenu,
  MdList,
  MdReceipt,
  MdPeople,
  MdCloudUpload,
  MdRefresh,
  MdSearch,
  MdPieChart,
  MdArrowForward,
  MdTimer,
  MdOutlinePriceCheck
} from "react-icons/md";
import { IoIosBasket, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  IoPricetag,
  IoStatsChart,
  IoCalendarNumberOutline,
  IoCartOutline,
  IoNutritionOutline,
} from "react-icons/io5";
import { TbRulerMeasure } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";
import { GrPlan } from "react-icons/gr";
import { RiDragDropFill } from "react-icons/ri";
import { MdEvent } from "react-icons/md";

import HomePage from "./pages/App/market/Home";
import UploadSAFTPage from "./pages/App/sales/UploadSAFT";
import General from "./pages/App/market/General";
import OwnBasketPage from "./pages/App/market/OwnBasketVision";
import FoodCostPageRecipes from "./pages/App/foodcost/FoodCostRecipes";
import FoodCostPageRecipesTable from "./pages/App/foodcost/FoodCostRecipesTable";
import ChangePasswordPage from "./pages/Profile/ChangePassword";
import "./theme/scrollbar.css";
import SalesPageShortTerm from "./pages/App/sales/SalesPageShortTerm";
import SalesPageLongTerm from "./pages/App/sales/SalesPageLongTerm";
import ForecastAnalysis from "./pages/App/forecast/ForecastAnalysis";
import MarginAnalysis from "./pages/App/market/MarginAnalysis";
import FoodCostAnalysis from "./pages/App/foodcost/FoodCostAnalysis";
import FoodCostRecipes from "./pages/App/foodcost/FoodCost-Recipes";
import FoodCostIngredients from "./pages/App/foodcost/FoodCost-Ingredients";
import Consultas from "./pages/App/consultas/Queries";
import Consulta from "./pages/App/consultas/Query";
import ForecastView from "./pages/App/forecast/ForecastView";
import Planning from "./pages/App/forecast/Planning";
import { useContext, useEffect, useState } from "react";
import MenuPage from "./pages/App/menu/recipes/MenuList";
import RecipeTec from "./pages/App/menu/recipes/RecipeTec";
import RecipeDetail from "./pages/App/menu/recipes/RecipeDetail";
import IngredDetailPage from "./pages/App/menu/ingredients/IngredientDetail";
import CustomersAnalysis from "./pages/App/sales/Customers";
import CustomerView from "./pages/App/sales/CustomerView";
import { IonReactRouter } from "@ionic/react-router";
import RestProdCategoriesPage from "./pages/App/user-setup/RestProdCategories";
import RestSelectProdsPage from "./pages/App/user-setup/RestSelectProds";
import ProfileRestaurantPage from "./pages/App/user-setup/ProfileRestaurant";
import SaftRestaurantPage from "./pages/App/user-setup/SaftRestaurant";
import IngredCreate from "./pages/App/menu/ingredients/IngredCreate";
import ReprocessarIngreds from "./pages/App/menu/ingredients/Reprocessar_ingreds";
import ReprocessarReceitas from "./pages/App/menu/recipes/Reprocess_recipes";
import Validate from "./pages/App/menu/recipes/Validate_recipes";
import TicketList from "./pages/App/tickets/ticket-list";
import TicketDetail from "./pages/App/tickets/ticket-detail";
import Pricing from "./pages/App/menu/pricing/Pricing";
import PlanningCreate from "./pages/App/forecast/PlaningCreate";
import EventsCreate from "./pages/App/events/EventsCreate";
import Custom from "./pages/App/forecast/CustomPlaning";
import useAuth from "./utils/hooks/useAuth";
import DataFilling from "./pages/App/data_filling/DataFilling";

setupIonicReact();
const AppTabs = () => {
  const { auth } = useAuth();
  //DO NOT DELETE
  //const {currentUser, email} = useUser();
  // if(email.includes("@landed")) {
  //   return <Redirect to="/backoffice/home" />;
  // }
  // if(!currentUser) {
  //   return <Redirect to="/login" />;
  // }

  //default as closed
  const initialSubMenuState = {
    "Custos das Receitas e Ingredientes": true,
    "Análise de Vendas": true,
    "Planeamento": true,
    Menu: true,
  };
  const [subMenu, setSubMenu] = useState<{ [key: string]: boolean }>(
    initialSubMenuState
  );


  function toggleSubMenu(page: string) {
    setSubMenu((value) => {
      return { ...value, [page]: !value[page] };
    });
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonMenu type="overlay" side="start" contentId="menu">
          <IonHeader>
            <IonToolbar>
              <IonTitle>Menu</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="scrollbarContent">
            <IonItemGroup>
              <Link to="/app/homepage">
                <IonItem lines="full" button detail={true}>
                  <MdHome size={24} title="Home Page" />

                  <IonLabel style={{ paddingLeft: "30px" }}>Home</IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/general">
                <IonItem lines="full" button detail={true}>
                  <MdList size={24} title="Visão Geral" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Preços - Visão Geral
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/ownbasket">
                <IonItem lines="full" button detail={true}>
                  <IoIosBasket size={24} title="Visão Cabaz Próprio" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Preços - Visão Cabaz Próprio
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/foodcost/recipes">
                <IonItem lines="full" button detail={true}>
                  <IoPricetag size={24} title="Custos das Receitas e Ingredientes" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Custos das Receitas e Ingredientes
                  </IonLabel>
                </IonItem>
              </Link>
              {/* <IonItem
                lines="full"
                button
                onClick={() =>
                  toggleSubMenu("Custos das Receitas e Ingredientes")
                }
              >
                <IoPricetag
                  size={24}
                  title="Custos das Receitas e Ingredientes"
                />

                <IonLabel style={{ paddingLeft: "30px" }}>
                  Custos das Receitas e Ingredientes
                </IonLabel>
                {subMenu["Custos das Receitas e Ingredientes"] ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowUp />
                )}
              </IonItem> */}
              {/* <IonMenuToggle
                hidden={subMenu["Custos das Receitas e Ingredientes"]}
                autoHide={false}
              >
                <Link to="/app/foodcost/recipes">
                  <IonItem
                    style={{ paddingLeft: "30px" }}
                    lines="full"
                    button
                    detail={true}
                  >
                    <MdBook size={24} title="Visão de Receitas" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Visão de Receitas
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle
                hidden={subMenu["Custos das Receitas e Ingredientes"]}
                autoHide={false}
              >
                <Link to="/app/foodcost/recipesTable">
                  <IonItem
                    style={{ paddingLeft: "30px" }}
                    lines="full"
                    button
                    detail={true}
                  >
                    <MdForward size={24} title="Drilldown de Receitas" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Drilldown de Receitas
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle> */}
              <Link to="/app/uploadsaft">
                <IonItem lines="full" button detail={true}>
                  <MdCloudUpload size={24} title="Upload de SAFT" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Upload de SAFT
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/foodcostAnalysis">
                <IonItem lines="full" button detail={true}>
                  <IoStatsChart size={24} title="Análise de FoodCost" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Análise de FoodCost
                  </IonLabel>
                </IonItem>
              </Link>
              <IonItem
                lines="full"
                button
                onClick={() => toggleSubMenu("Análise de Vendas")}
              >
                <MdReceipt size={24} title="Análise de Vendas" />

                <IonLabel style={{ paddingLeft: "30px" }}>
                  Análise de Vendas
                </IonLabel>
                {subMenu["Análise de Vendas"] ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowUp />
                )}
              </IonItem>
              <IonMenuToggle
                hidden={subMenu["Análise de Vendas"]}
                autoHide={false}
              >
                <Link to="/app/sales/short">
                  <IonItem
                    style={{ paddingLeft: "30px" }}
                    lines="full"
                    button
                    detail={true}
                  >
                    <MdTimer size={24} title="Curto Prazo" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Curto Prazo
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle
                hidden={subMenu["Análise de Vendas"]}
                autoHide={false}
              >
                <Link to="/app/sales/long">
                  <IonItem
                    lines="full"
                    style={{ paddingLeft: "30px" }}
                    button
                    detail={true}
                  >
                    <IoCalendarNumberOutline size={24} title="Longo Prazo" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Longo Prazo
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <Link to="/app/margin">
                <IonItem lines="full" button detail={true}>
                  <MdPieChart size={24} title="Análise de Margem" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Análise de Margem
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/forecasts">
                <IonItem lines="full" button detail={true}>
                  <MdArrowForward size={24} title="Análise de Forecast" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Análise Forecast
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/customers">
                <IonItem lines="full" button detail={true}>
                  <MdPeople size={24} title="Análise de Consumidor" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Análise de Consumidor
                  </IonLabel>
                </IonItem>
              </Link>
              <Link to="/app/consultas">
                <IonItem lines="full" button detail={true}>
                  <MdSearch size={24} title="Consultas" />

                  <IonLabel style={{ paddingLeft: "30px" }}>Consultas</IonLabel>
                </IonItem>
              </Link>
              {/*////////////////////////////////////////////////////////////////////////////////////  */}
              <IonItem
                lines="full"
                button
                onClick={() => toggleSubMenu("Planeamento")}
              >
                <IoCartOutline size={24} title="Planeamento" />

                <IonLabel style={{ paddingLeft: "30px" }}>
                  Planeamento de compras
                </IonLabel>
                {subMenu["Planeamento"] ? (
                  <IoIosArrowDown />
                ) : (
                  <IoIosArrowUp />
                )}
              </IonItem>
              <IonMenuToggle
                hidden={subMenu["Planeamento"]}
                autoHide={false}
              >
                <Link to="/app/planning">
                  <IonItem
                    style={{ paddingLeft: "30px" }}
                    lines="full"
                    button
                    detail={true}
                  >
                    <GrPlan size={22} title="Planeamento geral" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Planeamento geral
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle
                hidden={subMenu["Planeamento"]}
                autoHide={false}
              >
                <Link to="/app/planning/create">
                  <IonItem
                    lines="full"
                    style={{ paddingLeft: "30px" }}
                    button
                    detail={true}
                  >
                    <TbRulerMeasure size={24} title="Planeamento à medida" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Planeamento à medida
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              {/* <Link to="/app/planning">
                <IonItem lines="full" button detail={true}>
                  <IoCartOutline size={24} title="Planeamento de Compras" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Planeamento de Compras
                  </IonLabel>
                </IonItem>
              </Link> */}
              {/* /////////////////////&//////////////////////////////////////////////////////////////////// */}
              <Link to="/app/pricing">
                <IonItem lines="full" button detail={true}>
                  <MdOutlinePriceCheck size={24} title="Preço dos Pratos" />

                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Preço dos Pratos
                  </IonLabel>
                </IonItem>
              </Link>
              <IonItem
                lines="full"
                button
                onClick={() => toggleSubMenu("Menu")}
              >
                <MdMenu size={24} title="Menu" />
                <IonLabel style={{ paddingLeft: "30px" }}>
                  Gestão Técnica
                </IonLabel>
                {subMenu["Menu"] ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </IonItem>
              <IonMenuToggle hidden={subMenu["Menu"]} autoHide={false}>
                <Link to="/app/menu">
                  <IonItem
                    lines="full"
                    button
                    detail={true}
                    style={{ paddingLeft: "30px" }}
                  >
                    <MdMenu size={24} title="Receitas" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Gerir Receitas
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle hidden={subMenu["Menu"]} autoHide={false}>
                <Link to="/app/menu/validate_recipes">
                  <IonItem
                    lines="full"
                    button
                    detail={true}
                    style={{ paddingLeft: "30px" }}
                  >
                    <GrValidate size={24} title="Validar Receitas" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Validar Receitas
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle hidden={subMenu["Menu"]} autoHide={false}>
                <Link to="/app/menu/reprocess_recipes">
                  <IonItem
                    lines="full"
                    button
                    detail={true}
                    style={{ paddingLeft: "30px" }}
                  >
                    <MdRefresh size={24} title="Reprocessar Receitas" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Reprocessar Receitas
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle hidden={subMenu["Menu"]} autoHide={false}>
                <Link to="/app/menu/ingred/create">
                  <IonItem
                    lines="full"
                    button
                    detail={true}
                    style={{ paddingLeft: "30px" }}
                  >
                    <IoNutritionOutline size={24} title="Gerir Ingredientes" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Gerir Ingredientes
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              <IonMenuToggle hidden={subMenu["Menu"]} autoHide={false}>
                <Link to="/app/menu/reprocess_ingreds">
                  <IonItem
                    lines="full"
                    button
                    detail={true}
                    style={{ paddingLeft: "30px" }}
                  >
                    <MdRefresh size={24} title="Reprocessar Ingredientes" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Reprocessar Ingredientes
                    </IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
              {auth && auth.roles && auth.roles.includes("backoffice") &&
                <Link to="/app/dataFilling">
                  <IonItem lines="full" button detail={true}>
                    <RiDragDropFill size={24} title="Preenchimento de Dados" />
                    <IonLabel style={{ paddingLeft: "30px" }}>
                      Preenchimento de Dados
                    </IonLabel>
                  </IonItem>
                </Link>
              }
              <Link to="/app/events">
                <IonItem lines="full" button detail={true}>
                  <MdEvent size={24} title="Eventos" />
                  <IonLabel style={{ paddingLeft: "30px" }}>
                    Eventos
                  </IonLabel>
                </IonItem>
              </Link>
            </IonItemGroup>
          </IonContent>
        </IonMenu>
        <IonRouterOutlet id="menu">
          <Switch>
            <Route exact path="/app/dataFilling" component={DataFilling} />
            <Route exact path="/app/homepage" component={HomePage} />
            <Route exact path="/app/general" component={General} />
            <Route exact path="/app/ownbasket" component={OwnBasketPage} />
            <Route exact path="/app/tickets" component={TicketList} />
            <Route
              exact
              path="/app/tickets/detail/:id"
              component={TicketDetail}
            />
            <Route
              exact
              path="/app/foodcost/recipes"
              component={FoodCostPageRecipes}
            />
            <Route
              exact
              path="/app/events"
              component={EventsCreate}
            />
            <Route
              exact
              path="/app/foodcost/recipesTable"
              component={FoodCostPageRecipesTable}
            />
            <Route exact path="/app/uploadsaft" component={UploadSAFTPage} />
            <Route
              exact
              path="/app/profile"
              component={ProfileRestaurantPage}
            />
            <Route
              exact
              path="/app/profile/changePassword"
              component={ChangePasswordPage}
            />
            <Route
              exact
              path="/app/sales/short"
              component={SalesPageShortTerm}
            />
            <Route exact path="/app/sales/long" component={SalesPageLongTerm} />
            <Route exact path="/app/forecasts" component={ForecastAnalysis} />
            <Route
              exact
              path="/app/forecasts/forecast"
              component={ForecastView}
            />
            <Route exact path="/app/customers" component={CustomersAnalysis} />
            <Route
              exact
              path="/app/customers/customer"
              component={CustomerView}
            />
            <Route exact path="/app/margin" component={MarginAnalysis} />
            <Route
              exact
              path="/app/foodcostAnalysis"
              component={FoodCostAnalysis}
            />
            <Route
              exact
              path="/app/foodcost/recipes"
              component={FoodCostRecipes}
            />
            <Route
              exact
              path="/app/foodcost/recipes/ingr"
              component={FoodCostIngredients}
            />
            <Route
              exact
              path="/app/pricing"
              component={Pricing}
            />
            <Route exact path="/app/consultas" component={Consultas} />
            <Route exact path="/app/consultas/consulta" component={Consulta} />
            <Route exact path="/app/planning" component={Planning} />
            <Route exact path="/app/planning/create" component={PlanningCreate} />
            <Route exact path="/app/planning/custom/:id" component={Custom} />
            <Route exact path="/app/menu" component={MenuPage} />
            <Route
              exact
              path="/app/menu/ingred/create"
              component={IngredCreate}
            />
            <Route
              exact
              path="/app/menu/reprocess_ingreds"
              component={ReprocessarIngreds}
            />
            <Route
              exact
              path="/app/menu/reprocess_recipes"
              component={ReprocessarReceitas}
            />
            <Route exact path="/app/menu/recipe" component={RecipeDetail} />
            <Route exact path="/app/menu/recipe/tec" component={RecipeTec} />
            <Route
              exact
              path="/app/menu/ingredient"
              component={IngredDetailPage}
            />
            <Route
              exact
              path="/app/menu/rest-prod-categories"
              component={RestProdCategoriesPage}
            />
            <Route
              exact
              path="/app/menu/rest-select-prods"
              component={RestSelectProdsPage}
            />
            <Route
              exact
              path="/app/menu/rest-saft-rules"
              component={SaftRestaurantPage}
            />
            <Route
              exact
              path="/app/menu/validate_recipes"
              component={Validate}
            />
          </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default AppTabs;
