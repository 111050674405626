import React, { useState, useEffect, useCallback } from 'react';
import { IonContent, IonGrid, IonCol, IonRow, IonModal } from '@ionic/react';
import { FaPencilAlt } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { DarkModeProvider } from '../../../utils/contexts/theme.context';
import useDarkMode from '../../../pages/Profile/usedarkmode';
import { useApi } from '../../../utils/hooks/useAxiosPrivate';
import Loading from '../reusable/loading/loading';

import '../general.styles.scss';
import Toaster from '../reusable/Toaster';

interface Event {
  an_id: string;
  an_nome: string;
  an_descr: string;
  an_obs: string;
  an_dt_ini: string;
  an_dt_fim: string;
  price_ini: number;
}

interface EventListItem {
  recipe_id: string;
  recipe_name: string;
}

const Events: React.FC = () => {
  const { get, post } = useApi();
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalCreateInfo, setShowModalCreateInfo] = useState(false);
  const [inputValues, setInputValues] = useState<{ [key: string]: { item: EventListItem; num_doses: number } }>({});
  const [newEvent, setNewEvent] = useState({ name: '', description: '' });
  const [anId, setAnId] = useState('');
  const [EventList, setEventList] = useState<EventListItem[]>([]);
  const [refDate, setRefDate] = useState('');
  const [option, setOption] = useState('categories');


  const darkMode = useDarkMode();

  const getMaxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 15);
    return date.toISOString().split('T')[0];
  };

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get('list_events');
      console.log(response.data);
      setEvents(response.data);
    } catch (error) {
      console.error(error);
      Toaster.show('Erro ao carregar listas de eventeamento', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleInputChange = (recipeId: string, value: { item: EventListItem; num_doses: number }) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [recipeId]: value
    }));
  };

  const handleCreateEvent = async () => {
    setShowModalCreate(false);
    const newEventData = {
      an_id: '', // Add the 'an_id' property with an initial value
      an_nome: newEvent.name,
      an_descr: newEvent.description,
      an_obs: 'Em progresso...',
      an_dt_ini: 'Em progresso...',
      an_dt_fim: 'Em progresso...',
      price_ini: 0,
    };

    try {
      await post(`create_event/?name=${newEvent.name}&description=${newEvent.description}`, {});
      setEvents(prevEvents => [...prevEvents, newEventData]);
      fetchEvents();
    } catch (error) {
      console.error('Erro ao criar analise:', error);
    } finally {
      setNewEvent({ name: '', description: '' });
    }
  };

  const handleCreateEventCustom = async () => {
    setShowModalCreateInfo(false);
    const newEventData = {
      name: newEvent.name,
      recipes: inputValues
    };

    try {
      await post(`event_create/?an_id=${anId}`, newEventData);
      fetchEvents();
    } catch (error) {
      console.error('Erro ao criar analise:', error);
    }
  };

  const handleOpenCreateEventInfo = async (item: Event) => {
    setAnId(item.an_id);
    setShowModalCreateInfo(true);
    setLoading(true);

    try {
      const response = await get('shopping_list');
      setEventList(response.data);
    } catch (error) {
      console.error('Erro buscar LISTA DE COMPRAS:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const renderEventTable = () => (
    <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
      <thead className="bg-blue-dark text-white rounded">
        <tr>
          {['Nome', 'Data de Criação', 'Descritivo', 'Estado', 'Actions'].map((header) => (
            <th key={header} style={{ background: darkMode ? "#422655" : "" }} className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody style={{ backgroundColor: darkMode ? "#262853" : "" }} className="bg-white divide-y divide-gray-200">
        {events.map((item, index) => (
          <tr key={index}>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_nome}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_dt_ini}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_descr}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              {item.an_obs.includes("disponivel") ? "Disponível" : "Por configurar"}
            </td>
            <td style={{ color: darkMode ? "white" : "black" }} className="px-6 py-2 whitespace-no-wrap text-gray-600">
              <div className="flex space-x-4">
                {!item.an_obs.includes("disponivel") ? (
                  <button className="hover:scale-125 transition-transform duration-30" onClick={() => handleOpenCreateEventInfo(item)}>
                    <FaPencilAlt title="Configurar" size={24} />
                  </button>
                ) : (
                  <Link to={`/app/eventning/custom/${item.an_obs.match(/\d+/)?.[0] || '0'}`} className="hover:scale-125 transition-transform duration-30">
                    <MdSearch title="Consultar" size={32} />
                  </Link>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <DarkModeProvider>
      <IonContent className="ion-padding">
        <IonRow className="paddingButton">
          <button
            onClick={() => setShowModalCreate(true)}
            style={{ background: darkMode ? "#422655" : "" }}
            className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
          >
            + Criar Evento
          </button>
        </IonRow>
        <IonGrid>
          <IonGrid>
            <IonRow style={{ paddingBottom: "30px", paddingLeft: "50px", paddingRight: "50px" }}>
              <IonCol>
                {events.length > 0 ? (
                  renderEventTable()
                ) : (
                  <div style={{ color: darkMode ? "white" : "black" }} className="text-center text-2xl mt-10">
                    Sem eventos. Crie um novo evento.
                  </div>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonGrid>
        <Loading isOpen={loading} />
      </IonContent>

      {/* Modal Create */}
      <IonModal isOpen={showModalCreate} onDidDismiss={() => setShowModalCreate(false)}>
        <div className="overflow-auto p-10">
          <h2
            style={{
              color: darkMode ? "white" : "black",
              paddingBottom: "20px",
              textAlign: "center",
            }}
          >
            Criar novo evento
          </h2>

          <div className="flex flex-col gap-8">
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Nome</label>
              <input
                type="text"
                placeholder="Nome do Evento"
                value={newEvent.name}
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, name: e.target.value })
                }
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Explicação do Evento</label>
              <textarea
                placeholder="Explicação do Evento"
                value={newEvent.description}
                style={{
                  color: darkMode ? "white" : "black",
                  backgroundColor: darkMode ? "#422653d4" : "#d9d9d9",
                }}
                onChange={(e) =>
                  setNewEvent({
                    ...newEvent,
                    description: e.target.value,
                  })
                }
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className='flex gap-6 relative'>
              <div className={`rounded-full px-4 py-2 w-32 flex justify-center h-10 ${option === "categories" ? "bg-indigo-400" : "bg-zinc-200"}`} onClick={() => setOption("categories")}>
                <p className='z-[2]'>Categorias</p>
              </div>
              <div className={`rounded-full px-4 py-2 w-32 flex justify-center h-10  ${option === "ingredients" ? "bg-indigo-400" : "bg-zinc-200"}`} onClick={() => setOption("ingredients")}>
              <p className='z-[2]'>Ingredientes</p>
              </div>
              <div className='absolute bg-indigo-400 top-0 w-32 h-10 rounded-full z-[1]'></div>
            </div>
            <div className='flex flex-col gap-2'>
              <label className={`text-xl font-bold ${darkMode ? "text-white" : "text-black"}`}>Data de Início do Evento</label>
              <input
                type="date"
                value={refDate}
                max={getMaxDate()}
                style={{
                  color: darkMode ? 'white' : 'black',
                  backgroundColor: darkMode ? '#422653d4' : '#d9d9d9',
                }}
                onChange={(e) => setRefDate(e.target.value)}
                className='w-full px-4 py-2 border-none bg-zinc-200 text-black rounded-[25px] outline-zinc-300'
              />
            </div>
            <div className="flex justify-between gap-x-3 pt-5">
              <button
                onClick={handleCreateEvent}
                disabled={
                  newEvent.name === "" ||
                  newEvent.description === ""
                }
                style={{ background: darkMode ? "#422655" : "" }}
                className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Criar
              </button>
              <button
                onClick={() => setShowModalCreate(false)}
                style={{ background: darkMode ? "#422655" : "" }}
                className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </IonModal>

      {/* Modal Create INFO */}
      <IonModal isOpen={showModalCreateInfo} onDidDismiss={() => setShowModalCreateInfo(false)}>
        <h2
          style={{
            color: darkMode ? "white" : "black",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          Configurar evento
        </h2>
        <IonCol style={{ padding: "50px", paddingTop: "0px" }}>
          <div className="pb-5">

          </div>
          <div className="flex justify-between gap-x-3 pt-5">
            <button
              onClick={handleCreateEventCustom}
              style={{ background: darkMode ? "#422655" : "" }}
              className="w-full text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Criar
            </button>
            <button
              onClick={() => setShowModalCreateInfo(false)}
              style={{ background: darkMode ? "#422655" : "" }}
              className="w-full text-white bg-error p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Cancelar
            </button>
          </div>
        </IonCol>
      </IonModal>
    </DarkModeProvider>
  );
};

export default Events;