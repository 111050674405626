import React, { useEffect, useState } from 'react';
import { IonContent, IonGrid, IonCol, IonRow, IonSearchbar } from "@ionic/react";
import "../general.styles.scss";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import MyTable from "../reusable/table/table-component";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import Loading from "../reusable/loading/loading";
import InfoComponent from "../reusable/info/info.component";
import Toaster from '../reusable/Toaster';

const Planning = () => {
  const { get } = useApi();
  const darkMode = useDarkMode();

  const [tableData, setTableData] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  });
  const [lastSalesDate, setLastSalesDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQueries, setSearchQueries] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
  });

  const titles = {
    q1: "Quantidade de doses a considerar na estimativa de compras",
    q2: "Quantidades usadas nas receitas - Valores médios de PVP e estimativa de custo",
    q3: "Ingredientes - Por SKU de referência",
    q4: "Ingredientes usados em receitas - Os 3 PVP's mais baixos"
  };

  const columnDefinitions = {
    q1: [
      { headerName: 'Receita', field: 'receita' },
      { headerName: 'Descrição', field: 'rest_prod_desc' },
      { headerName: 'Doses', field: 'doses' }
    ],
    q2: [
      { headerName: 'Ingrediente', field: 'ingr_name' },
      { headerName: 'Quantidade(kg)', field: 'qt_ingr_kg' },
      { headerName: 'Preço médio', field: 'price_avg' },
      { headerName: 'Custo Estimado', field: 'custo_estimado' }
    ],
    q3: [
      { headerName: 'Ingrediente', field: 'ingr_name' },
      { headerName: 'Quantidade(kg)', field: 'qt_ingr_kg' },
      { headerName: 'Detalhes', field: 'sku_prod_name' },
      { headerName: 'Fornecedor', field: 'sku_prov_name' },
      { headerName: 'Categoria', field: 'sku_category' },
      { headerName: 'Preço', field: 'price' },
      { headerName: 'Data do Preço', field: 'price_date' },
      { headerName: 'Custo Estimado', field: 'custo_estimado' }
    ],
    q4: [
      { headerName: 'Ingrediente', field: 'ingr_name' },
      { headerName: 'Quantidade(kg)', field: 'qt_ingr_kg' },
      { headerName: 'Detalhes', field: 'sku_prod_name' },
      { headerName: 'Fornecedor', field: 'sku_prov_name' },
      { headerName: 'Categoria', field: 'sku_category' },
      { headerName: 'Preço', field: 'price' },
      { headerName: 'Data do Preço', field: 'price_date' },
      { headerName: 'Custo Estimado', field: 'custo_estimado' },
      { headerName: 'Rank', field: 'rank' }
    ],
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const endpoints = ['dash4_sales_last_date', 'd9_q1', 'd9_q2', 'd9_q3', 'd9_q4'];
        const results = await Promise.allSettled(
          endpoints.map(endpoint => get(endpoint, { signal: controller.signal }))
        );

        let errorMessages = "Não existem dados para:\n";

        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            if (index === 0) {
              setLastSalesDate(result.value.data);
            } else {
              const key = `q${index}`;
              if(result.value.data.length === 0) {
                errorMessages += `-> ${titles[key]}\n`;
              }
              setTableData(prev => ({ ...prev, [key]: result.value.data }));
            }
          } else {
            console.error(`Error fetching ${endpoints[index]}:`, result.reason);
            if (index !== 0) {
              const key = `q${index}`;
              setTableData(prev => ({ ...prev, [key]: null }));
            }
            errorMessages += `-> ${titles[`q${index}`]}\n`;
          }
        });
        if (errorMessages !== "Não existem dados para:\n") {
          Toaster.show(errorMessages.slice(0, -1), "error"); // Remove last \n\n
        }
      } catch (error) {
        console.error('Error in fetchData:', error);
        Toaster.show("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, []);

  const handleSearchChange = (query, key) => {
    setSearchQueries(prev => ({ ...prev, [key]: query }));
  };

  const renderSearchBar = (key) => (
    <IonCol size="12" size-xl="12">
      <div className={`search ${key !== 'q1' ? 'top' : ''}`}>
        <IonSearchbar
          value={searchQueries[key]}
          onIonChange={(e) => handleSearchChange(e.detail.value, key)}
          placeholder={`Pesquisar por ${key === 'q1' ? 'receita' : 'ingrediente'}`}
          style={{ '--ion-background-color': darkMode ? '#262853' : 'white' }}
        />
      </div>
    </IonCol>
  );

  const renderTable = (key) => (
    <IonCol size="12" size-xl="12">
      <MyTable
        rowData={tableData[key]}
        columnDefs={columnDefinitions[key]}
        title={titles[key]}
        darkMode={darkMode}
      />
    </IonCol>
  );



  return (
    <IonContent>
      <IonGrid>
        <IonGrid>
          <div className="w-full padding">
            {lastSalesDate && (
              <InfoComponent>
                <p>Dados disponíveis até a data: {new Date(lastSalesDate).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
              </InfoComponent>
            )}
          </div>
          <IonRow style={{ paddingBottom: '100px' }} className='px-[50px]'>
            {Object.keys(tableData).map(key => (
              <React.Fragment key={key}>
                {tableData[key] && tableData[key].length > 0 && renderSearchBar(key)}
                {tableData[key] && tableData[key].length > 0 && renderTable(key)}
              </React.Fragment>
            ))}
          </IonRow>
        </IonGrid>
      </IonGrid>
      <Loading isOpen={loading} />
    </IonContent>
  );
};

export default Planning;